import {useEffect, useRef, useState} from 'react';
import { useRouter } from 'next/router';
import { IntlProvider } from 'react-intl';
import { setCookie, parseCookies } from 'nookies';
import { Analytics } from '@vercel/analytics/react';
import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import en from '@/lang/en.json';
import fr from '@/lang/fr.json';

import '@/styles/tailwind.css';
import 'focus-visible';
import Banner from '@/components/Banner';
import {segmentAnalytics, segmentIdentify} from "@/analytics/segment-alanytics";

const messages = {
  en,
  fr,
};

function usePrevious(value) {
  let ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export default function App({ Component, pageProps, router }) {
  const { locale } = useRouter();
  let previousPathname = usePrevious(router.pathname);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    segmentIdentify('portfolio')
    const cookies = parseCookies();
    console.log(cookies)
    if (cookies.releaseBannerVisible === 'false') {
      setIsVisible(false);
    }
  }, []);

  const handleDismiss = () => {
    setIsVisible(false);
    setCookie(null, 'releaseBannerVisible', 'false', {
      maxAge: 60 * 60 * 24 * 7, // 1 week
      path: '/',
    });
  };

  return (
    <>
      <div className='fixed inset-0 flex justify-center sm:px-8'>
        <div className='flex w-full max-w-7xl lg:px-8'>
          <div className='w-full bg-white ring-1 ring-zinc-100 dark:bg-zinc-900 dark:ring-zinc-300/20' />
        </div>
      </div>
      <div className='relative'>
        <IntlProvider locale={locale} messages={messages[locale]}>
          {!isVisible ? <div/> : <Banner handleDismiss={handleDismiss}/> }
          <Header />
          <main>
            <Component previousPathname={previousPathname} {...pageProps} />
            <Analytics />
          </main>
          <Footer />
        </IntlProvider>
      </div>
    </>
  );
}
