import { AnalyticsBrowser } from '@segment/analytics-next'

// We can export this instance to share with rest of our codebase.
const segmentAnalytics = AnalyticsBrowser.load({ writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY });

export const SegmentEvent = {
  STAY_UP_TO_DATE_JOIN_BTN: 'stay-up-to-date-join-btn',
}

export const segmentIdentify = (identity) => {
  return segmentAnalytics.identify(identity);
}

export const segmentTrackEven = async (segmentEvent, properties) => {
  return await segmentAnalytics.track(segmentEvent, properties)
}
